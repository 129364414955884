<template>
    <div class="_wrap">
        <phoneMenu :menu="p_menu" :p_current="2"></phoneMenu>

        <div class="_auto">
            <h4></h4>

            <div class="_list" v-if="total > 0">
                <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
                    <div class="_img">
                        <img :src="$IMG_URL + item.cover" alt="">
                    </div>
                    <div class="_p">{{ item.title }}...</div>

                    <div class="_ydl">
                        <div class="_h4">阅读量</div>
                        <div class="_span _impact">{{ item.hits }}</div>
                    </div>
                </div>
            </div>


            <div class="_noData" v-else>暂无数据....</div>


            <div class="_page">
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page"
                    :limit.sync="queryParams.page_num" @pagination="getList" />
            </div>
        </div>


        <fixIcon />
        <coment ref="comentRef"></coment>
        <mainFooter ref="mainFooterRef"></mainFooter>
    </div>
</template>

<script>
import { getDataById } from '../../api/getData'
import phoneMenu from '../../components/phoneMenu/phoneMenu.vue';

export default {
    components: {
        phoneMenu
    },
    data() {
        return {
            list: [],
            total: 0,
            queryParams: {
                page: 1,
                page_num: 8
            },
            p_menu: [
                {
                    text: "学术动态",
                    path: "/study"
                },
                {
                    text: "学术委员会",
                    path: "/studyCom"
                }, {
                    text: "书刊精选",
                    path: "/studyBook"
                },
            ]
        }
    },
    async mounted() {
        await this.getList()
        this.$scrollTop()
    },
    methods: {
        async getList() {
            const params = {
                cate_id: 33,
                page: this.queryParams.page,
                page_num: this.queryParams.page_num,
                keywords: '',
                type_id: '',
                times: '',
            }
            await getDataById(params).then(res => {
                console.log(res)
                this.list = res.data.data
                this.total = res.data.total

                if (this.total == 0) {

                }

            })
        },
        goDetail(item) {
            let url = window.origin
            window.open(`${url}/studyDetail?id=${item.id}&tit=书刊精选`)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._page
{
    text-align: center;
    margin-top: 23px;
}

@mixin tit($url)
{
    width: 299px;
    height: 52px;
    background-image: url($url);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-bottom: 40px;
}

._wrap
{
    width: 100%;
    background-color: #F8F5EE;

    ._auto
    {
        width: 85%;
        margin: 0 auto;
        margin-top: 200px;

        h4
        {
            @include tit("../../assets/images/h4-20.png");

        }
    }
}


._list
{
    display: flex;
    flex-wrap: wrap;

    ._li
    {
        width: 25%;
        height: 460px;
        cursor: pointer;
        position: relative;
        background-color: #fff;
        // border: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;

        &:nth-child(1)
        {
            border-left: 1px solid #E6E6E6;
            border-top: 1px solid #E6E6E6;
        }

        &:nth-child(2)
        {
            border-top: 1px solid #E6E6E6;
        }

        &:nth-child(3)
        {
            border-top: 1px solid #E6E6E6;
        }

        &:nth-child(4)
        {
            border-top: 1px solid #E6E6E6;
        }

        &:nth-child(5)
        {
            border-left: 1px solid #E6E6E6;

        }

        ._img
        {
            width: 330px;
            height: 330px;
            overflow: hidden;
            margin: 0 auto;
            margin-top: 45px;

            img
            {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .5s;
            }

            img:hover
            {
                transform: scale(1.1);
            }
        }

        ._p
        {
            color: #333;
            font-size: 24px;
            font-weight: bold;
            width: 85%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 17px auto 0;
        }

        ._ydl
        {
            width: 56px;
            height: 59px;
            // background: #C0A062;

            background-image: url("../../assets/images/ydl.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            color: white;
            position: absolute;
            top: 0;
            left: 20px;
            padding: 4px;

            text-align: center;

            ._h4
            {
                border-bottom: 1px solid #E3D1BB;
                font-size: 15px;
                padding-bottom: 2px;
            }

            ._span
            {
                font-weight: bold;
                font-size: 16px;
                margin-top: 2px;
            }
        }
    }
}


._noData{
    text-align: center;
    font-size: 20px;

}
._wrap
{
    @include respondTo('phone')
    {
        ._auto
        {
            margin-top: 30px;
            width: 95% !important;
            padding: 0;

            >h4
            {
                background-image: url("../../assets/images/phone/skjx.png");
                width: 173px;
                height: 30px;
                margin-bottom: 30px;
            }

            ._list
            {
                border: 1px solid #E6E6E6;

                ._li
                {
                    width: 50%;
                    height: 240px;

                    ._img
                    {
                        width: 100%;
                        height: 80%;
                        margin-top: 0;
                        margin-top: 10px;
                    }

                    ._p
                    {
                        font-size: 18px;
                        margin-top: 5px;
                    }

                    ._ydl
                    {
                        left: 10px;
                    }
                }

                ._li:nth-child(1)
                {
                    border-right: 1px solid #E6E6E6;
                    border-bottom: 1px solid #E6E6E6;
                    border-left: none;
                    border-top: none;
                }

                ._li:nth-child(2)
                {
                    border-bottom: 1px solid #E6E6E6;
                    border-top: none;
                }

                ._li:nth-child(3)
                {
                    border-right: 1px solid #E6E6E6;
                    border-top: none;
                }

                ._li:nth-child(4)
                {
                    border-top: none;
                }
            }

            ._page
            {
                margin-bottom: 30px;
            }
        }
    }
}
</style>